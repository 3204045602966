import React, { FC, memo } from 'react';

import { renderConditionRow, renderFullPrice } from '../saleCard';
import { PropertiesProps } from './propertiesForSale.types';

type RenderForSaleTrueArgs = Omit<
  PropertiesProps,
  'forSale' | 'weeklyRent' | 'monthlyRent'
>;

type RenderForSaleTrue = (args: RenderForSaleTrueArgs) => JSX.Element;

type ConvertNullToTBD = (value: number | null) => number | string;

const convertNullToTBD: ConvertNullToTBD = (value) =>
  value === null ? 'TBD' : `${value}%`;

const renderForSaleTrue: RenderForSaleTrue = ({
  isSold,
  isSharedOwnership,
  minDeposit,
  minSharePercentage,
  minShareValue,
  price,
  maxGovernmentLoanPercent,
  maxGovernmentLoanValue,
}) => {
  const fullPrice = renderFullPrice({ price, isSold });
  const minimumDeposit = renderConditionRow('Minimum deposit', minDeposit);
  const isMaxGovernmentLoanPercent = maxGovernmentLoanPercent !== null;

  if (isSharedOwnership || isMaxGovernmentLoanPercent) {
    const name = isMaxGovernmentLoanPercent
      ? `Up to ${convertNullToTBD(maxGovernmentLoanPercent)} government loan`
      : `${convertNullToTBD(minSharePercentage)} Share from`;

    const price = isMaxGovernmentLoanPercent
      ? maxGovernmentLoanValue
      : minShareValue;

    return (
      <>
        {minimumDeposit}
        {renderConditionRow(name, price)}
        {fullPrice}
      </>
    );
  }

  return (
    <>
      {minimumDeposit}
      {fullPrice}
    </>
  );
};

export const Properties: FC<PropertiesProps> = memo(
  ({ forSale, weeklyRent, monthlyRent, ...forSaleTrueProps }) => {
    if (forSale) {
      return renderForSaleTrue(forSaleTrueProps);
    }

    return (
      <>
        {renderConditionRow('Weekly rent', weeklyRent)}
        {renderFullPrice({ name: 'Monthly rent', price: monthlyRent })}
      </>
    );
  }
);

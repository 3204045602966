import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';
import { fade } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';

import { Schema as KitSchema } from '@keaze/web/components/schema';
import { Status as KitStatus, StatusProps } from '@keaze/web/components/status';
import { MyTheme, WithMyTheme } from '@keaze/web/theme';

interface CardProps extends WithMyTheme {
  $isSold?: boolean;
  $isHorizontal?: boolean;
  $featured?: boolean;
  $premium?: boolean;
}

interface FavoriteButtonProps extends WithMyTheme {
  $favorite: boolean;
}

export const Header = styled(Box)`
  ${({
    theme: {
      colors,
      transitions: { duration, easing },
    },
  }) => `
    position: relative;
    width: 100%;
    height: 210px;
    border: 4px solid ${colors.greys.greys0};
    transition: ${duration.standard}ms ${easing.easeInOut};

    & > a {
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `}
`;

export const Title = styled(Typography)`
  ${({
    theme: {
      palette,
      transitions: { easing, duration },
    },
  }) => `
    color: ${palette.primary.main};
    margin-right: 5px;
    flex: 1 1 auto;
    transition: ${duration.short}ms ${easing.easeInOut};
  `}
`;

export const Address = styled(Typography)``;

export const FullPrice = styled(Typography)`
  font-weight: 700;
`;

export const FullPriceName = styled(Typography)``;

export const Content = styled(Box)``;

const featuredStylesCard = ({
  colors,
  palette,
  transitions: { easing, duration },
}: MyTheme) => `
  background-color: ${colors.blues.blues3};
  transition: ${duration.standard}ms ${easing.easeInOut};

  ${Header} {
    border-color: ${colors.blues.blues3};
  }

  ${Content} {
    padding: 0 10px 8px 10px;

    ${ContentHeader} {
      ${specialStylesContentHeader(palette)};
    }

    ${PriceCondition},
    ${FullPrice},
    ${FullPriceName} {
      color: ${palette.common.white};
    }
  }  

  &:hover {
    background-color: ${palette.action.hover};

    ${Header} {
      border-color: ${palette.action.hover};
    }
  }
`;

const horizontalStylesCard = () => `
  display: flex;
  flex-direction: row; 
  align-items: center;

  ${Header} {
    width: 100%;
    max-width: 300px;
    height: 170px;
    margin-right: 30px;
    flex: 1 1 auto;
  }

  ${Content} {
    flex: 1 1 auto;
    margin-top: 0;
  }
`;

const soldStylesCard = (palette: Palette) => `
  ${Header} {
    & > * {
      filter: grayscale(100%);
    }
  }

  ${Title} {
    color: ${palette.text.primary};
    opacity: 0.7;
  }

  ${FullPrice} {
    text-decoration: line-through;
    color: ${palette.text.primary};
    opacity: 0.7;
  }

  ${FullPriceName} {
    flex: 1 1 auto;
  }
`;

export const PriceCondition = styled(Typography)`
  opacity: 0.7;
`;

export const Statuses = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

export const Status = styled(KitStatus)<StatusProps>`
  margin-right: 1px;

  &:last-child {
    margin-right: 0;
  }
`;

export const SchemaWrapper = styled(Grid)`
  position: absolute;
  top: 0;
  right: -10px;
  width: auto;
`;

export const Rooms = styled.div`
  ${({ theme: { palette } }) => `
    display: flex;
    align-items: center;

    &:first-child {
      margin-right: 15px;
    }

    svg {
      fill: ${palette.text.secondary};
    }
  `}
`;

export const RoomsCount = styled(Typography)`
  margin-left: 7px;
`;

export const ContentHeader = styled.header`
  ${({
    theme: {
      colors,
      transitions: { duration, easing },
    },
  }) => `
    position: relative;
    margin-bottom: 8px;
    padding: 8px 0;
    transition: ${duration.standard}ms ${easing.easeInOut};

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background: ${colors.greys.greys1};
      opacity: 0.4;
    }
  `}
`;

const premiumStylesCard = ({ palette, colors }: MyTheme) => `

  ${Header} {
    border: none;
  
    ${SchemaWrapper} {
      top: 4px;
      right: -6px;
    }
  }

  ${ContentHeader} {
    ${specialStylesContentHeader(palette)};

    background-color: ${colors.greens.green1};
    padding: 8px 10px;
  }

  &:hover {
    ${ContentHeader} {
      background-color: ${colors.greens.green2};
    }
  }
`;

const specialStylesContentHeader = (palette: Palette) => `
  &::after {
    content: none;
  }

  ${Title},
  ${Address},
  ${RoomsCount} {
    color: ${palette.common.white};
  }

  ${Rooms} {
    svg {
      opacity: 0.5;
      fill: ${palette.common.white};
    }
  }

  ${Status} {
    color: ${palette.common.white};
    background-color: ${fade(palette.common.white, 0.14)};
  }
`;

export const Card = styled(Box)<CardProps>`
  ${({
    theme,
    $isSold = false,
    $isHorizontal = false,
    $featured,
    $premium,
  }) => `
    position: relative;
    margin: 0;

    &:hover {
      ${Title} {
        color: ${theme.palette.action.hover};
      }
    }

    ${$isHorizontal ? horizontalStylesCard() : ''}
    ${$isSold ? soldStylesCard(theme.palette) : ''} 
    ${$featured ? featuredStylesCard(theme) : ''};
    ${$premium ? premiumStylesCard(theme) : ''};
  `}
`;

export const SoldChip = styled(Typography)`
  ${({ theme: { palette, colors, typography } }) => `
    color: ${palette.text.primary};
    font-size: ${typography.pxToRem(9)};
    line-height: ${typography.pxToRem(9)};
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid ${colors.greys.greys10};
    border-radius: 3px;
    margin-right: 10px;
    padding: 2px 3px;
  `}
`;

export const Premises = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  margin-left: 5px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Schema = styled(KitSchema)`
  margin-right: 1px;

  &:last-child {
    margin-right: 0;
  }
`;

const favoriteStyles = (palette: Palette) => `
  svg path {
    &:last-child {
      fill: ${palette.secondary.main};
    }
  }
`;

export const FavoriteButton = styled(IconButton)<
  IconButtonProps & FavoriteButtonProps
>`
  ${({
    theme: {
      palette,
      colors,
      transitions: { duration, easing },
    },
    $favorite,
  }) => `
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px;
    z-index: 9;

    svg path {
      transition: all ${duration.standard}ms ${easing.easeInOut};

      &:first-child {
        fill: ${colors.greys.greys21};
        opacity: 0.65;
      }

      &:last-child {
        fill: transparent;
      }
    }

    &:hover {
      svg path {
        &:first-child {
          fill: ${colors.greys.greys22};
          opacity: 0.50;
        }
      }
    }

    ${$favorite ? favoriteStyles(palette) : ''};
  `}
`;

export const Link = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;

  a {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

import { FC, memo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

import { Card, Content, ContentHeader, Header } from '../saleCard.styles';

type Props = {
  isHorizontal?: boolean;
};

export const SaleCardSkeleton: FC<Props> = memo(({ isHorizontal = false }) => (
  <Card component="figure" $isHorizontal={isHorizontal}>
    <Header>
      <Skeleton variant="rect" width="100%" height="100%" />
    </Header>
    <Content component="figcaption">
      <ContentHeader>
        <Grid container justify="space-between">
          <Skeleton variant="text" width="45%" />
          <Skeleton variant="text" width="20%" />
        </Grid>
        <Grid container justify="space-between">
          <Skeleton variant="text" width="55%" />
          <Skeleton variant="text" width="20%" />
        </Grid>
      </ContentHeader>
      <Grid container>
        <Grid container justify="space-between">
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="15%" />
        </Grid>
        <Grid container justify="space-between">
          <Skeleton variant="text" width="35%" />
          <Skeleton variant="text" width="15%" />
        </Grid>
        <Grid container justify="space-between">
          <Skeleton variant="text" width="30%" />
          <Skeleton variant="text" width="15%" />
        </Grid>
      </Grid>
    </Content>
  </Card>
));

import { Listing, Application } from '@keaze/web/common/interfaces';

export type SaleCardSchema = {
  text: string;
  color: string;
};

export interface AvailabilityStatus {
  name: string;
  code: 'coming-soon' | 'available' | 'under-offer' | 'closed';
}

export enum AvailabilityStatusCode {
  ComingSoon = 'coming-soon',
  ForSale = 'available',
  Sold = 'closed',
}

export type OnChange = () => void;

export interface SaleCardProps extends Listing {
  isHorizontal?: boolean;
  openInNewTab?: boolean;
  withFavorite?: boolean;
  isLoading?: boolean;
  priority?: boolean;
  withoutImageOptimize?: boolean;
  onChange?: OnChange;
  application?: Application;
}

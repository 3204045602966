import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';

import { OnChange } from './saleCard.types';
import { Listing } from '@keaze/web/common/interfaces';
import { useFavorite } from '@keaze/web/hooks/useFavorite';
import { getSearchParamsForListing } from '@keaze/web/utils/params';

type UseSaleCardExpected = {
  link: UrlObject;
  favorite: boolean;
  onFavoriteClick: () => void;
};

type UseSaleCard = (
  listing: Listing,
  onChange?: OnChange
) => UseSaleCardExpected;

export const useSaleCard: UseSaleCard = (listing, onChange) => {
  const router = useRouter();
  const { favorite, deletedFavorite, onFavoriteClick } = useFavorite({
    listingId: listing.id,
    listingSlug: listing.slug,
    listingSchemes: listing.schemes,
    favourite: listing.favourite,
  });

  const schemeSlug = listing.schemes[0].slug;
  const link: UrlObject = {
    pathname: `/${schemeSlug}/properties/${encodeURIComponent(listing.slug)}`,
    query: getSearchParamsForListing(router.query),
  };

  useEffect(() => {
    if (deletedFavorite && onChange) {
      onChange();
    }
  }, [deletedFavorite]);

  return {
    link,
    favorite,
    onFavoriteClick,
  };
};

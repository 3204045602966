import React, { FC, useMemo } from 'react';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import {
  Premises,
  Card,
  Content,
  ContentHeader,
  FullPrice,
  FullPriceName,
  Header,
  Statuses,
  Status,
  PriceCondition,
  Row,
  Title,
  Address,
  Rooms,
  RoomsCount,
  SchemaWrapper,
  SoldChip,
  Schema as UISchema,
  FavoriteButton,
  Link,
} from './saleCard.styles';
import {
  SaleCardSchema,
  SaleCardProps,
  AvailabilityStatusCode,
  AvailabilityStatus,
} from './saleCard.types';
import { Properties, PropertiesProps } from './propertiesForSale';
import { DisplayAs } from '@keaze/web/common/enums';
import { useSaleCard } from './saleCard.hook';
import { Ifa } from '@keaze/web/common/interfaces';

type RenderContentHeaderArgs = {
  title: string;
  address: string;
  bedrooms: number[];
  bathrooms: number[];
  availabilityStatus: AvailabilityStatus;
  newBuild: boolean;
};

type RenderContentHeader = (args: RenderContentHeaderArgs) => JSX.Element;

type RenderConditionRow = (name: string, price: number | null) => JSX.Element;

type RenderStatuses = (
  newBuild: boolean,
  availabilityStatus: AvailabilityStatus
) => JSX.Element;

type GeneratePremises = (array: number[]) => string | null;

type RenderSchemesArgs = {
  schemes: SaleCardSchema[];
  isSold: boolean;
  schemaColorSold: string;
};

type RenderSchemaArgs = {
  text: string;
  backgroundColor: string;
  isNotLast: boolean;
};

type RenderSchemes = (args: RenderSchemesArgs) => JSX.Element;

type RenderSchema = (args: RenderSchemaArgs) => JSX.Element;

type RenderFullPriceArgs = {
  name?: string;
  price: number | null;
  isSold?: boolean;
};

type RenderPrice = (price: number | null) => JSX.Element | string;

type RenderFullPrice = (args: RenderFullPriceArgs) => JSX.Element;

type RenderFavoriteButton = (
  favorite: boolean,
  onFavoriteClick: () => void
) => JSX.Element;

type RenderApplicationStatusRow = (
  status: string,
  useIfa: boolean
) => JSX.Element;

type RenderIfaStatusRow = (status: string) => JSX.Element;

const BedroomIcon = () => (
  <svg width="19" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 14v1.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5v-7a2.5 2.5 0 00-2-2.45V.5a.5.5 0 00-.5-.5h-14a.5.5 0 00-.5.5v5.55A2.5 2.5 0 000 8.5v7a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V14h13zM1.44 7.44A1.5 1.5 0 012.5 7h14A1.5 1.5 0 0118 8.5V9H1v-.5c0-.398.158-.78.44-1.06zM1 10v3h17v-3H1zm17 4h-1v1h1v-1zM1 14h1v1H1v-1zM16 1v5h-1.5V4.5A1.5 1.5 0 0013 3h-1.5A1.5 1.5 0 0010 4.5V6H9V4.5A1.5 1.5 0 007.5 3H6a1.5 1.5 0 00-1.5 1.5V6H3V1h13zm-2.5 3.5V6H11V4.5a.5.5 0 01.5-.5H13a.5.5 0 01.5.5zM8 4.5V6H5.5V4.5A.5.5 0 016 4h1.5a.5.5 0 01.5.5z"
      fillOpacity=".5"
    />
    <path
      d="M16.5 6.459l.4.081a2 2 0 00-.4-.04v-.041zM2.1 6.54l.4-.081V6.5a2 2 0 00-.4.04z"
      strokeOpacity=".5"
    />
  </svg>
);

const BathroomIcon = () => (
  <svg width="12" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.024 4.032A4.5 4.5 0 0112 4.5V16h-1V4.5a3.5 3.5 0 00-6.97-.46A3.5 3.5 0 017 7.5V9H0V7.5a3.5 3.5 0 013.024-3.468zM3.5 5A2.5 2.5 0 001 7.5V8h5v-.5A2.5 2.5 0 003.5 5z"
    />
    <path d="M2 11.5a.5.5 0 11-1 0 .5.5 0 011 0zM6 11.5a.5.5 0 11-1 0 .5.5 0 011 0zM3.5 12a.5.5 0 100-1 .5.5 0 000 1zM1 14.5a.5.5 0 11-1 0 .5.5 0 011 0zM6.5 15a.5.5 0 100-1 .5.5 0 000 1zM4 14.5a.5.5 0 11-1 0 .5.5 0 011 0z" />
  </svg>
);

const FavoriteIcon = () => (
  <svg width="29" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity=".25"
      d="M3.413 3.139a9.067 9.067 0 000 12.823l8.396 8.396.14.142 2.6 2.6v-.001l.002.002 7.04-7.04.134-.137 3.963-3.964A9.067 9.067 0 0014.55 1.81C11.058-.326 6.434.117 3.413 3.139z"
      fill="#5B5B5B"
    />
    <path
      d="M14.554 19.586L7.83 12.862a3.978 3.978 0 115.626-5.625l.39.391.708.707.707-.707.392-.391a3.978 3.978 0 015.625 5.625s0 0 0 0l-6.724 6.724z"
      stroke="#fff"
      strokeWidth="2"
    />
  </svg>
);

const generatePremises: GeneratePremises = (array) => {
  const arrayWithoutZero = array.filter((number) => number !== 0);

  if (array.length === 0) {
    return null;
  }

  if (array.length === 1 && array[0] === 0) {
    return 'Studio';
  }

  if (arrayWithoutZero.length === 1) {
    return arrayWithoutZero.toString();
  }

  const min = Math.min(...arrayWithoutZero);
  const max = Math.max(...arrayWithoutZero);

  return `${min} - ${max}`;
};

const renderContentHeader: RenderContentHeader = ({
  title,
  address,
  bedrooms,
  bathrooms,
  newBuild,
  availabilityStatus,
}) => {
  const bedroomCount = generatePremises(bedrooms);
  const bathroomCount = generatePremises(bathrooms);
  const isNotStudio = bedroomCount !== 'Studio';

  return (
    <ContentHeader>
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        wrap="nowrap"
      >
        <Title variant="h3">{title}</Title>
        {renderStatuses(newBuild, availabilityStatus)}
      </Grid>
      <Row>
        <Address variant="body2">{address}</Address>
        <Premises>
          {!!bedroomCount && (
            <Rooms>
              {isNotStudio && <BedroomIcon />}
              <RoomsCount variant="body2">{bedroomCount}</RoomsCount>
            </Rooms>
          )}
          {!!bathroomCount && (
            <Rooms>
              <BathroomIcon />
              <RoomsCount variant="body2">{bathroomCount}</RoomsCount>
            </Rooms>
          )}
        </Premises>
      </Row>
    </ContentHeader>
  );
};

const renderPrice: RenderPrice = (price) => {
  if (price === null) {
    return 'TBD';
  }

  return <>&#163;{price.toLocaleString()}</>;
};

export const renderConditionRow: RenderConditionRow = (name, price) => (
  <Row>
    <PriceCondition variant="body2">{name}</PriceCondition>
    <PriceCondition variant="body2">{renderPrice(price)}</PriceCondition>
  </Row>
);

const renderStatuses: RenderStatuses = (newBuild, availabilityStatus) => {
  const withCustomStatus =
    availabilityStatus.code !== AvailabilityStatusCode.ForSale &&
    availabilityStatus.code !== AvailabilityStatusCode.Sold;

  return (
    <Statuses>
      {withCustomStatus && <Status name={availabilityStatus.name} />}
      {newBuild && <Status />}
    </Statuses>
  );
};

const renderSchema: RenderSchema = ({ text, backgroundColor, isNotLast }) => (
  <UISchema
    key={text}
    withoutTail={isNotLast}
    backgroundColor={backgroundColor}
  >
    {text}
  </UISchema>
);

const renderSchemes: RenderSchemes = ({ schemes, isSold, schemaColorSold }) => (
  <SchemaWrapper container>
    {schemes.map(({ color, text }, index) => {
      const backgroundColor = isSold ? schemaColorSold : color;
      const isNotLast = schemes.length - 1 !== index;

      return renderSchema({ text, backgroundColor, isNotLast });
    })}
  </SchemaWrapper>
);

export const renderFullPrice: RenderFullPrice = ({
  name = 'Full price',
  price,
  isSold = false,
}) => (
  <Row>
    <FullPriceName variant="body2">{name}</FullPriceName>
    {isSold && <SoldChip>sold</SoldChip>}
    <FullPrice variant="body1">{renderPrice(price)}</FullPrice>
  </Row>
);

const renderFavoriteButton: RenderFavoriteButton = (
  favorite,
  onFavoriteClick
) => (
  <FavoriteButton $favorite={favorite} onClick={onFavoriteClick}>
    <FavoriteIcon />
  </FavoriteButton>
);

const prettyApplicationStatus = (status: string, useIfa?: boolean) => {
  const statuses = {
    'ready-to-apply': 'Ready to apply',
    'application-submitted': useIfa ? 'Additional info required' : 'Submitted',
    'sign-off-received': 'Signed off',
    rejected: 'Rejected',
    'resubmit-requested': 'Resubmit requested',
    'submitted-to-ifa': 'Submitted to IFA',
  };
  return statuses[status] ?? 'Ready to apply';
};

const renderApplicationStatusRow: RenderApplicationStatusRow = (
  status,
  useIfa
) => (
  <Row>
    <PriceCondition variant="body2">Application status</PriceCondition>
    <PriceCondition variant="body2">
      {prettyApplicationStatus(status, useIfa)}
    </PriceCondition>
  </Row>
);

const prettyIfaStatus = (status: string) => {
  const statuses = {
    'not-submitted': 'Not submitted',
    pending: 'Pending',
    passed: 'Pending',
    failed: 'Failed',
  };
  return statuses[status] ?? 'Not submitted';
};

const renderIfaStatusRow: RenderIfaStatusRow = (status) => (
  <Row>
    <PriceCondition variant="body2">IFA Status</PriceCondition>
    <PriceCondition variant="body2">{prettyIfaStatus(status)}</PriceCondition>
  </Row>
);

export const SaleCard: FC<SaleCardProps> = ({
  isHorizontal = false,
  openInNewTab = false,
  withFavorite = false,
  priority = false,
  withoutImageOptimize = false,
  onChange,
  application,
  ...listing
}) => {
  const { link, favorite, onFavoriteClick } = useSaleCard(listing, onChange);
  const {
    colors: { greys },
  } = useTheme();
  const schemaColorSold = greys.greys9;
  const isSold =
    listing.availabilityStatus.code === AvailabilityStatusCode.Sold;
  const propertyForSaleProps: PropertiesProps = {
    isSold,
    isSharedOwnership: listing.isSharedOwnership,
    minDeposit: listing.depositValue,
    minSharePercentage: listing.minSharePercentage,
    minShareValue: listing.minShareValue,
    price: listing.fullMarketPrice,
    maxGovernmentLoanPercent: listing.maxGovernmentLoanPercent,
    maxGovernmentLoanValue: listing.maxGovernmentLoanValue,
    forSale: listing.forSale,
    weeklyRent: listing.weeklyRent,
    monthlyRent: listing.monthlyRent,
  };
  const linkTarget = openInNewTab ? '_blank' : '';
  const imageSrc =
    listing.mainImage === null ? '' : listing.mainImage.mediumSize;
  const featured = listing.displayAs === DisplayAs.Featured;
  const premium = listing.displayAs === DisplayAs.Premium;

  const image = useMemo(() => {
    if (withoutImageOptimize) {
      return <img src={imageSrc} alt={listing.name} />;
    }

    return (
      <NextImage
        src={imageSrc}
        alt={listing.name}
        layout="fill"
        objectFit="cover"
        priority={priority}
      />
    );
  }, [withoutImageOptimize, priority, imageSrc, listing]);

  return (
    <Card
      $isSold={isSold}
      $isHorizontal={isHorizontal}
      $featured={featured}
      $premium={premium}
      component="figure"
    >
      <Link>
        <NextLink href={link} prefetch={false} passHref>
          <a target={linkTarget} />
        </NextLink>
      </Link>
      <Header>
        {image}
        {renderSchemes({ schemes: listing.schemes, isSold, schemaColorSold })}
        {withFavorite && renderFavoriteButton(favorite, onFavoriteClick)}
      </Header>
      <Content component="figcaption">
        {renderContentHeader({
          title: listing.name,
          address: listing.displayAddress,
          bedrooms: listing.bedrooms,
          bathrooms: listing.bathrooms ?? [],
          newBuild: listing.newBuild ?? false,
          availabilityStatus: listing.availabilityStatus,
        })}
        <Properties {...propertyForSaleProps} />
        {listing.enablePassportForm
          ? renderApplicationStatusRow(
              application?.passportStatus ?? 'ready-to-apply',
              listing.ifaEnabled
            )
          : null}
        {listing.enablePassportForm &&
        listing.ifaEnabled &&
        application?.passportStatus != 'ready-to-apply'
          ? renderIfaStatusRow(application?.ifaResponse ?? 'not-submitted')
          : null}
      </Content>
    </Card>
  );
};
